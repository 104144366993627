export default {
	phone: "Please enter a valid phone number",
	fullname: "Please enter a valid full name that contains at least two words",
	name: "Please enter a valid name",
	email: "Please enter a valid email address",
	select: "Please select an option",
	checkbox: "Please confirm this checkbox",
	number: "Please enter a valid number",
	required: "This field is required",
	null: "This field is required",
};
