import {Configuration} from "./interfaces/configuration";
import "intl-tel-input/build/css/intlTelInput.css";
import "./index.css";
import * as $ from "jquery";
import * as iti from "intl-tel-input";
import {EngleaseForm, generateItiConfig} from "./interfaces/englease-form";
import {FIELDS} from "./interfaces/fields";
import isEmpty from "validator/es/lib/isEmpty";
import isEmail from "validator/es/lib/isEmail";
import isNumeric from "validator/es/lib/isNumeric";
import * as intlTelInput from "intl-tel-input";
import ar from "./translations/ar";
import en from "./translations/en";
import {Translation} from "./interfaces/translation";
// eslint-disable-next-line no-unused-vars
export declare type ELP_BEHAVIOUR = (form: EngleaseForm) => void | boolean;

class EngleaseLP {
    public behaviours: ELP_BEHAVIOUR[] = [];
    public debug = false;
    public debugCount = 0;
    public ready = false;
    static forms: EngleaseForm[] = [];
    static itiInstances: { [key: string]: { [key: string]: intlTelInput.Plugin } } = {};
    static window$: JQuery<Window>;
    private errors: Translation = null;

    // eslint-disable-next-line no-unused-vars
    constructor(private locale: string = "ar") {
        this.errors = this.locale === "ar" ? ar : en;

        $(() => {
            EngleaseLP.window$ = $(window)
            window["dataLayer"].push({event: "page_load_start", timestamp: performance.timing.navigationStart})
            window["dataLayer"].push({event: "page_load_finish", timestamp: performance.timing.loadEventEnd})

            $("[href][id],a[id],button[id],[href]").on('click', function () {
                const anchor = $(this)
                window["dataLayer"].push({
                    event: "interaction",
                    element_type: anchor.prev().prop("nodeName"),
                    element_location: anchor.attr("id"),
                    window_location: "left: " + anchor.position().left + ", top: " + anchor.position().top
                })
            })
            EngleaseLP.window$.on('scroll', () => {
                const windowBottom = EngleaseLP.window$.scrollTop() + EngleaseLP.window$.height();

                for (const form of EngleaseLP.forms) {
                    const formTop = form.form$.offset().top;
                    if (form.shown || windowBottom < formTop) {
                        continue;
                    }
                    let percentage = Math.round((windowBottom - form.form$.offset().top) / form.form$.height() * 100);
                    percentage = percentage >= 100 ? 100 : percentage;
                    if (percentage >= 25 && !form.showed['25']) {
                        form.showed['25'] = true
                        window["dataLayer"].push({
                            event: "form_show",
                            show_amount: percentage.toString() + '%',
                            location: form.id
                        })
                    }
                    if (percentage >= 50 && !form.showed['50']) {
                        form.showed['50'] = true
                        window["dataLayer"].push({
                            event: "form_show",
                            show_amount: percentage.toString() + '%',
                            location: form.id
                        })
                    }
                    if (percentage >= 75 && !form.showed['75']) {
                        form.showed['75'] = true
                        window["dataLayer"].push({
                            event: "form_show",
                            show_amount: percentage.toString() + '%',
                            location: form.id
                        })
                    }
                    if (percentage >= 100 && !form.showed['100']) {
                        form.showed['100'] = true
                        form.shown = true;
                        window["dataLayer"].push({
                            event: "form_show",
                            show_amount: percentage.toString() + '%',
                            location: form.id
                        })
                    }


                }
            })
        })
    }

    private log(message?: any, ...optionalParams: any[]) {
        if (!this.debug) {
            return;
        }
        console.log(`Englease LP (${++this.debugCount}): ` + message, ...optionalParams);
    }

    public registerForm(configuration: Configuration) {
        const form: EngleaseForm = {
            id: configuration.formId,
            config: configuration,
            form$: $(`#${configuration.formId}`),
            inputs$: {},
            redirect: configuration.redirect,
            webhook: configuration.webhook,
            valid: false,
            errors: {},
            data: {},
            showed: {
                '25': false,
                '50': false,
                '75': false,
                '100': false
            },
            shown: false,
            hasBehaviourErrors: false
        };

        EngleaseLP.forms.push(form);
        this.setupFields(form);
        this.restoreLocalData(form);
        form.submit$ = form.form$.find('[type="submit"]');

        form.submit$.on("click", (event: any) => {
            event.preventDefault();
            event.stopPropagation();
            form.submit$.attr("disabled", "disabled");
            const submitContent = form.submit$.html();
            form.submit$.html('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>');
            this.clearErrors(form);
            this.buildFormData(form, configuration);
            if (!this.valid(form)) {
                form.submit$.removeAttr("disabled");
                form.submit$.html(submitContent);
                return;
            }
            this.applyBehaviours(form);
            if (form.hasBehaviourErrors) {
                form.hasBehaviourErrors = false;
                form.submit$.removeAttr("disabled");
                form.submit$.html(submitContent);
                return;
            }
            this.saveLocalData(configuration, form);
            if (form.config.verifyEmails) {
                $.ajax({
                    type: "POST",
                    url: `https://dashboard.englease.com/user-exists`,
                    data: {email: form.data['email']},
                }).done(function (res: any) {
                    if (res.success === true) {
                        window.location.assign('https://dashboard.englease.com/login')
                    } else {
                        if (form.config.webhook) {
                            $.ajax({
                                type: "POST",
                                url: form.webhook,
                                data: form.data,
                                success: function () {
                                    window["dataLayer"].push({event: "form_submit_success"});
                                    window["dataLayer"].push({
                                        event: "form_submit",
                                        location: form.id,
                                        status: "success"
                                    })
                                    form.submit$.removeAttr("disabled");
                                    window.location.href = form.redirect;
                                    form.submit$.html(submitContent);
                                },
                                error: function (err: any) {
                                    console.log(err);
                                    window["dataLayer"].push({event: "form_submit", location: form.id, status: "fail"})
                                    form.submit$.removeAttr("disabled");
                                    form.submit$.html(submitContent);
                                },
                            });
                        } else {
                            window["dataLayer"].push({event: "form_submit_success"})
                            window["dataLayer"].push({event: "form_submit", location: form.id, status: "success"})

                            window.location.href = form.redirect;
                            form.submit$.removeAttr("disabled");
                            form.submit$.html(submitContent);
                        }
                    }
                }).fail(function () {
                    if (form.config.webhook) {
                        $.ajax({
                            type: "POST",
                            url: form.webhook,
                            data: form.data,
                            success: function () {
                                window["dataLayer"].push({event: "form_submit_success"});
                                window["dataLayer"].push({
                                    event: "form_submit",
                                    location: form.id,
                                    status: "success"
                                })
                                form.submit$.removeAttr("disabled");
                                window.location.href = form.redirect;
                                form.submit$.html(submitContent);
                            },
                            error: function (err: any) {
                                console.log(err);
                                window["dataLayer"].push({event: "form_submit", location: form.id, status: "fail"})
                                form.submit$.removeAttr("disabled");
                                form.submit$.html(submitContent);
                            },
                        });
                    } else {
                        window["dataLayer"].push({event: "form_submit_success"})
                        window["dataLayer"].push({event: "form_submit", location: form.id, status: "success"})

                        window.location.href = form.redirect;
                        form.submit$.removeAttr("disabled");
                        form.submit$.html(submitContent);
                    }
                });
            } else {
                if (form.config.webhook) {
                    $.ajax({
                        type: "POST",
                        url: form.webhook,
                        data: form.data,
                        success: function () {
                            window["dataLayer"].push({event: "form_submit_success"});
                            window["dataLayer"].push({event: "form_submit", location: form.id, status: "success"})
                            form.submit$.removeAttr("disabled");
                            window.location.href = form.redirect;
                            form.submit$.html(submitContent);
                        },
                        error: function (err: any) {
                            console.log(err);
                            window["dataLayer"].push({event: "form_submit", location: form.id, status: "fail"})
                            form.submit$.removeAttr("disabled");
                            form.submit$.html(submitContent);
                        },
                    });
                } else {
                    window["dataLayer"].push({event: "form_submit_success"})
                    window["dataLayer"].push({event: "form_submit", location: form.id, status: "success"})

                    window.location.href = form.redirect;
                    form.submit$.removeAttr("disabled");
                    form.submit$.html(submitContent);
                }
            }
        });
    }

    private clearErrors(form: EngleaseForm) {
        form.form$.find(".elementor-message-danger").remove();
        form.form$.find(".elementor-field-group").removeClass("has-errors");
    }

    private applyBehaviours(form: EngleaseForm) {
        for (const behaviour of this.behaviours) {
            const success = behaviour(form);
            if (success === false) {
                form.hasBehaviourErrors = true;
                break;
            }
        }
    }

    private setupFields(form: EngleaseForm) {
        EngleaseLP.itiInstances[form.id] = {};
        for (const field of Object.keys(form.config.fields)) {
            form.inputs$[field] = form.form$.find(`[name="form_fields[${field}]"]`).first();
            form.errors[field] = {};
            if (form.config.fields[field] === FIELDS.PHONE) {
                this.log("Setting up:", field);
                EngleaseLP.itiInstances[form.id][field] = iti(form.inputs$[field].get(0), generateItiConfig(form));
            }
            form.inputs$[field].on('focus', () => {
                window["dataLayer"].push({event: 'interaction', element_name: field, type: 'focus', location: form.id})
            })
            form.inputs$[field].on('blur', () => {
                window["dataLayer"].push({
                    event: 'interaction',
                    element_name: field,
                    value: form.inputs$[field].val(),
                    type: 'blur',
                    location: form.id
                })
            })
            form.inputs$[field].on('change', () => {
                window["dataLayer"].push({
                    event: 'interaction',
                    element_name: field,
                    value: form.inputs$[field].val(),
                    type: 'change',
                    location: form.id
                })
            })

        }
    }

    private restoreLocalData(form: EngleaseForm) {
        if (localStorage.getItem(form.id)) {
            const localData = JSON.parse(localStorage.getItem(form.id));
            for (const field of Object.keys(form.config.fields)) {
                if (form.config.fields[field] === FIELDS.PHONE) {
                    EngleaseLP.itiInstances[form.id][field].setNumber(`${localData[field].startsWith('+')? localData[field] : ('+' + localData[field])}`);
                    continue;
                }
                form.inputs$[field].val(localData[field]);
            }
        }
    }

    private saveLocalData(configuration: Configuration, form: EngleaseForm) {
        localStorage.setItem(configuration.formId, JSON.stringify(form.data));
    }

    buildFormData(form: EngleaseForm, configuration: Configuration) {
        for (const field of Object.keys(configuration.fields)) {
            if (form.config.fields[field] === FIELDS.PHONE) {
                form.data[field] = EngleaseLP.itiInstances[configuration.formId][field].getNumber(0);
                form.data[field + "_country"] = EngleaseLP.itiInstances[configuration.formId][field].getSelectedCountryData().iso2.toUpperCase();
                continue;
            }
            if (form.config.fields[field] === FIELDS.CHECKBOX) {
                form.data[field] = form.inputs$[field].is(":checked");
            }
            form.data[field] = form.inputs$[field].val();
        }
        for (const key of configuration.dynamicData) {
            form.data[key] = this.getFromUriOrCookies(key);
        }
        for (const key of Object.keys(configuration.staticData)) {
            form.data[key] = configuration.staticData[key];
        }
        form.data["tz"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        form.data["source"] = window.location.href;
        form.data["e_module"] = this.isExternalModule() ? this.getFromUriOrCookies("e_module") : "Leads";
        form.data["e_layout"] = this.isExternalModule() ? this.getFromUriOrCookies("e_layout") : "4460408000000091055";
    }

    private isExternalModule() {
        return this.getFromUriOrCookies("e_module") != "" && this.getFromUriOrCookies("e_layout") != "";
    }

    private getFromUriOrCookies(key: string) {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop: string) => searchParams.get(prop),
        });
        let value = Object.hasOwnProperty(key) ? params[key] : false;
        if (!value) {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookiePair = cookies[i].split("=");
                if (key == cookiePair[0].trim()) {
                    value = decodeURIComponent(cookiePair[1]);
                }
            }
        }
        if (!value) value = "";
        return value;
    }

    private valid(form: EngleaseForm): boolean {
        form.errors = {};
        for (const field of Object.keys(form.config.fields)) {
            const output = {};
            //stop validation incase of null field
            if (isEmpty(form.data[field] as string) && (form.config.fields[field] !== FIELDS.NULL)) {
                output["required"] = this.errors.required;
                form.errors[field] = output;
                continue;
            }
            switch (form.config.fields[field]) {
                case FIELDS.PHONE:
                    if (!EngleaseLP.itiInstances[form.id][field].isValidNumber()) {
                        output["phone"] = this.errors.phone;
                    }
                    break;
                case FIELDS.EMAIL:
                    if (!isEmail(form.data[field] as string)) {
                        output["email"] = this.errors.email;
                    }
                    break;
                case FIELDS.FULLNAME:
                    if (!/[\p{L}\p{M}*+]+\s+[\p{L}\p{M}*+].+/ug.test(form.data[field] as string)) {
                        output["fullname"] = this.errors.fullname;
                    }
                    break;
                case FIELDS.NAME:
                    if (!/[\p{L}\p{M}*+].+/ug.test(form.data[field] as string)) {
                        output["name"] = this.errors.name;
                    }
                    break;

                case FIELDS.CHECKBOX:
                    if (!form.data[field]) {
                        output["checkbox"] = this.errors.checkbox;
                    }
                    break;
                case FIELDS.NUMBER:
                    if (!isNumeric(form.data[field] as string)) {
                        output["number"] = this.errors.number;
                    }
                    break;
                case FIELDS.SELECT:
                    break;
                case FIELDS.NULL:
                    break;
                default:
                    break;
            }
            if (Object.keys(output).length) {
                form.errors[field] = output;
            }
        }
        if (Object.keys(form.errors).length) {
            this.log(form);
            for (const field of Object.keys(form.errors)) {
                const error = Object.values(form.errors[field]).join("\r\n");
                form.inputs$[field]
                    .closest(".elementor-field-group")
                    .addClass("has-errors")
                    .append(`<div class="elementor-message-danger">${error} </div>`);
                window["dataLayer"].push({
                    event: 'validation_shown',
                    element_name: field,
                    value: form.data[field],
                    location: form.id,
                    reason: error
                })
            }

            return false;
        } else {
            return true;
        }
    }
}

export default EngleaseLP;
export const ELP_FIELDS = FIELDS;
