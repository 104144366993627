export enum FIELDS {
	NAME = "name",
	FULLNAME = "fullname",
	SELECT = "select",
	EMAIL = "email",
	CHECKBOX = "checkbox",
	PHONE = "phone",
	NUMBER = "number",
	NULL = "null",
}
