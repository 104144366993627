import { Configuration } from "./configuration";
import { get } from "jquery";
export const generateItiConfig = (form: EngleaseForm) => {
  return {
    preferredCountries: ["eg", "ly", "tn", "ma", "dz", "sy", "bh", "jo", "ae", "ps", "iq", "lb", "kw", "om", "ye", "sa"],
    excludeCountries: ["kp", "il"],
    formatOnDisplay: false,
    separateDialCode: true,
    dropdownContainer: document.body,
    customContainer: "elementor-field-type-tel elementor-field-group elementor-column elementor-col-100",
    initialCountry: "auto",
    geoIpLookup: (success: any): void => {
      get("https://ipinfo.io?token=f189bf262d9748", function () {}, "jsonp").always(function (resp) {
        let countryCode = resp && resp.country ? resp.country : "ae";
        form.country = countryCode;
        success(countryCode);
      });
    },
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
  };
};
export interface EngleaseForm {
  id: string;
  form$: JQuery;
  inputs$: { [key: string]: JQuery };
  redirect: string;
  webhook: string;
  config: Configuration;
  submit$?: JQuery;
  data?: { [key: string]: string | number | string[] | boolean };
  country?: string;
  errors: { [key: string]: { [key: string]: string } };
  valid: boolean;
  showed?: {[key: string]: boolean};
  shown?: boolean;
  hasBehaviourErrors: boolean
}
